/* Products */
.page-type-product body, .xm-grid-product {
	font-size: 16px;
	line-height: 1.625;
}
.page-type-product #stocklevel {
	float: right;
}
.xm-grid-product .productTabContent > div {
	margin-bottom: 1rem;
}
.xm-grid-product .productTabContent ul {
	list-style: disc;
	padding-left: 10px;
	margin-bottom: 1rem;
}
.xm-grid-product .productTabContent ul li {
	line-height: 1.625;
}
/* Buttons
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

.page-type-product #stocklevel a.button::-moz-focus-inner
{
    padding:0 !important;
    border:0 !important;
} /* FF Fix */
.page-type-product #stocklevel a.button {
    -webkit-border-fit:lines; /* <- Safari & Google Chrome Fix */
    overflow:visible; width:auto; border:0; padding:0; margin:0; background:transparent; cursor:pointer;
    display: inline-block;
}
.page-type-product #stocklevel a.button span {
    float: left;
    display:block;
    padding:0;
    font-size:12px;
    text-align:center;
    white-space:nowrap;
    background-color:#ddd;
    color:#333;

    transition:         color 300ms ease-in-out, background-color 300ms ease-in-out;
    -moz-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
    -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
    -o-transition:      color 300ms ease-in-out, background-color 300ms ease-in-out;
}
.page-type-product #stocklevel a.button:hover span
{ background-color:#333; color:#fff; }
.page-type-product a.button span span
{ height:45px; line-height:45px; }

.xm-grid-product button.button span span {
	height: 36px;
	line-height: 36px;
}

/* Smaller button - for inline display with input boxes
-------------------------------------------------------------- */
.page-type-product #stocklevel a.button.btn-inline span span { padding:0 10px; line-height:36px !important; height:36px !important; }
.page-type-product #stocklevel a.button span {
    background-color: #eeeeee;
    color: #000;
}

.page-type-product .product-options dt {
	font-weight: normal;
	line-height: 6px;
}

.page-type-product .product-options .variantion_con {
	line-height: 12px;
	padding-bottom: 12px;
}

.page-type-product .product-options .variantion_con:last-child {
	line-height: 6px;
	padding-bottom: 6px;
}


.page-type-product span.was {
	text-decoration: line-through;
	text-transform: uppercase;
}

.page-type-product span.now {
	font-weight: bolder;
	text-transform: uppercase;
	clear: both;
	display: block;
	color: red;
}

.page-type-product span.red {
	color: red !important;
}

.page-type-product .block .actions {
	text-align: inherit;
	border-top: none;
}

.page-type-product #breadcrumbs li:last-child span.breadcrumb-separator {
	display: none;
}

.page-type-product .product-options dt label.required em {
	float: inherit;
	margin-left: inherit;
}

.page-type-product .product-options dt {
	margin-bottom: 10px;
}

.page-type-product #cart_product_comment {
	margin-bottom: 20px;
}

.page-type-product #cart_product_comment textarea {
	width: 100%;
}

.page-type-product .kit-contents {
	padding: 20px 0;
}

.page-type-product .data-table th,
.page-type-product .data-table td {
	padding: 0 0;
}

.page-type-product .data-table th,
.page-type-product .data-table tr:last-child {
	padding: 10px 0;
}
.page-type-product .product-view .box-reviews .form-add .data-table th,
.page-type-product .product-view .box-reviews .form-add .data-table td
{
    padding: 10px;
}